import {
  createRef,
  useState,
  useMemo,
  FC,
  useEffect,
  useCallback,
  useReducer,
  useRef,
  ReactNode,
} from "react";
import styled from "styled-components";
import shuffle from "lodash/shuffle";
import {
  Box,
  Container,
  PrimaryButton,
  Flex,
  Grid,
  Heading,
  Text,
} from "@moonpig/launchpad-components";
import { colorValue } from "@moonpig/launchpad-theme-moonpig";
import { Prompt } from "./components/Prompt";
import SoundPlayer from "./components/SoundPlayer";
import SoundEffectOptions from "./components/SoundEffectOptions";
import { getRandomArrayIndex } from "./utils/getRandomArrayIndex";
import {
  CHEER,
  RICK_ROLL_MAX,
  RICK_ROLL_MIN,
  RICK_ROLL_SRC,
  SOUND_CONFIG,
} from "./constants";
import teams from "./TEAMS_LIST.json";
import { appStateReducer, teamStateReducer } from "./reducers";
import { DadJoke } from "./components/DadJoke";
import { Spacer } from "./components/Spacer";
import { Riddle } from "./components/Riddle";
import { Hangman } from "./components/Hangman";

type EntertainmentMap = {
  [key: string]: ReactNode;
};

const entertainmentMap: EntertainmentMap = {
  DAD_JOKE: <DadJoke />,
  RIDDLE: <Riddle />,
  HANGMAN: <Hangman />,
};

const StyledAppContainer = styled(Container)`
  height: 100vh;
  padding: 12px;
  box-sizing: border-box;

  & > div {
    height: 100%;
  }

  color: ${colorValue("textOne")};
`;

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 12px;
  text-align: left;
  min-height: 64px;
`;

const StyledList = styled.ul`
  margin-top: 50px;
  list-style-type: none;
  padding: 0;
`;

type ListItemProps = {
  hasGivenUpdate: boolean;
  isSelected: boolean;
  isAbsent: boolean;
  allowDisabling: boolean;
};

const StyledTextButton = styled(Text)<ListItemProps>`
  font-size: 32px;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: unset;
  color: ${(props) => {
    if (props.isSelected) return colorValue("offerTwo");
    if (props.hasGivenUpdate || props.isAbsent) return "#6C7584";
    return "#00204D";
  }};
  text-decoration: ${(props) =>
    (props.hasGivenUpdate || props.isAbsent) && !props.isSelected
      ? "line-through"
      : "normal"};
  position: relative;
  ${(props) =>
    props.allowDisabling &&
    `cursor: pointer;
    &:hover {
      &::after { 
      content: '⨉';
      font-size: 24px;
      position: absolute;
      top: 6px; 
      right: -20px;`}
    }
  }
`;

const StyledSurpriseVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding-top: 64px;
`;

let allowSuprises = false;
let allowCheer = false;
let teamEntertainment: string | null = null;

const sounds = Object.entries(SOUND_CONFIG);
const randomSoundBag = [...sounds];

const App: FC = () => {
  const [isLooping, setIsLooping] = useState<boolean>(false);
  const [selectedSound, setSelectedSound] = useState<string>("random");
  const [isRickRoll, setIsRickRoll] = useState<boolean>(false);
  const [isShuffleMode, setIsShuffleMode] = useState<boolean>(true);

  const soundTicks = useRef(30);

  const [appState, dispatchAppState] = useReducer(appStateReducer, "START");

  const [teamState, dispatchTeamStateAction] = useReducer(teamStateReducer, {
    name: "",
    members: [],
    selected: "",
    previouslySelected: [],
    absent: [],
  });

  const activeParticipants = teamState.members.filter(
    (participant: string) => !teamState.absent.includes(participant)
  );

  const soundPlayerRef = createRef<HTMLAudioElement>();

  const remainingParticipants = useMemo(() => {
    return activeParticipants.filter(
      (participant: string) =>
        !teamState.previouslySelected.includes(participant)
    );
  }, [teamState, activeParticipants]);

  const fetchParticipants = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("teamId");

    const { name, members, rickrolls, entertainment, cheer } = teams.find(
      (team) =>
        id === team.id ||
        (process.env.NODE_ENV === "development" && team.id === "test")
    )!;

    dispatchTeamStateAction({
      type: "SET_TEAM",
      payload: {
        members: shuffle(members),
        name,
      },
    });

    allowSuprises = rickrolls;
    teamEntertainment = entertainment;
    allowCheer = cheer;
  }, []);

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);

  useEffect(() => {
    if (!isShuffleMode) {
      const { src = "", ticks = 30 } = SOUND_CONFIG[selectedSound] || {};
      soundTicks.current = ticks;
      if (soundPlayerRef.current) {
        soundPlayerRef.current.src = src;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSound, isShuffleMode]);

  const handleNextPersonClick = () => {
    if (remainingParticipants.length === 0) {
      appState === "START"
        ? dispatchAppState({ type: "GO_TO_AOBS" })
        : dispatchAppState({ type: "GO_TO_FINISHED" });
    }

    if (isShuffleMode) {
      if (randomSoundBag.length === 0) {
        console.log("Sounds exhausted, refilling");
        randomSoundBag.push(...sounds);
      }

      const randomSongIndex = getRandomArrayIndex(randomSoundBag);
      const [songId, { src, ticks }] = randomSoundBag.splice(
        randomSongIndex,
        1
      )[0];
      setSelectedSound(songId);
      soundTicks.current = ticks;
      if (soundPlayerRef.current) {
        soundPlayerRef.current.src = src;
      }
    }

    if (isRickRoll) {
      setIsRickRoll(false);
      if (soundPlayerRef.current) {
        soundPlayerRef.current.src = SOUND_CONFIG[selectedSound]?.src;
      }
    }

    if (allowSuprises) {
      if (remainingParticipants.length >= 2) {
        const roll = Math.floor(Math.random() * 1000);
        console.log("Roll was: ", roll);
        if (roll >= RICK_ROLL_MIN && roll <= RICK_ROLL_MAX) {
          console.log("You just got Rick Rolled!");
          if (soundPlayerRef.current) {
            soundPlayerRef.current.src = RICK_ROLL_SRC;
          }

          setIsRickRoll(true);
        }
      }
    }

    selectNextPerson();
  };

  const selectNextPerson = () => {
    let nextPerson = "";
    let counter = soundTicks.current;

    if (remainingParticipants.length < 2) {
      dispatchTeamStateAction({
        type: "SELECT_PARTICIPANT",
        payload: {
          selected: remainingParticipants[0],
        },
      });

      dispatchTeamStateAction({
        type: "SET_PREVIOUSLY_SELECTED",
        payload: {
          selected: remainingParticipants[0],
        },
      });

      if (
        remainingParticipants.length === 1 &&
        selectedSound !== "off" &&
        allowCheer
      ) {
        if (soundPlayerRef.current) {
          soundPlayerRef.current.src = CHEER.src;
          soundPlayerRef.current.play();
        }
      }

      return;
    }

    if (selectedSound !== "off" && remainingParticipants.length >= 2) {
      soundPlayerRef?.current?.play();
    }

    function loopRandomly() {
      setIsLooping(true);
      setTimeout(() => {
        nextPerson =
          remainingParticipants[getRandomArrayIndex(remainingParticipants)];

        dispatchTeamStateAction({
          type: "SELECT_PARTICIPANT",
          payload: { selected: nextPerson },
        });
        counter--;

        if (counter > 0) {
          loopRandomly();
        } else {
          dispatchTeamStateAction({
            type: "SET_PREVIOUSLY_SELECTED",
            payload: {
              selected: nextPerson,
            },
          });
          setIsLooping(false);
        }
      }, 90);
    }

    loopRandomly();
  };

  const buttonLabel = useMemo(() => {
    if (remainingParticipants.length === 0) {
      return "Finish up";
    }

    if (remainingParticipants.length === activeParticipants.length) {
      return "Start us off!";
    }

    return "Select next person";
  }, [remainingParticipants, activeParticipants]);

  const toggleAbsentParticipant = (participant: string) => {
    if (teamState.previouslySelected.length > 0) return;

    if (!teamState.absent.includes(participant)) {
      dispatchTeamStateAction({
        type: "SET_ABSENT_PARTICIPANT",
        payload: {
          participant,
        },
      });
    } else {
      dispatchTeamStateAction({
        type: "REMOVE_ABSENT_PARTICIPANT",
        payload: {
          participant,
        },
      });
    }
  };
  return (
    <StyledAppContainer>
      <StyledHeader>
        <Grid justifyContent="space-between">
          <Box flex={1}>
            <Text fontWeight="bold">Team: {teamState.name}</Text>
          </Box>
          <Box flex={1}>
            <Heading textAlign="center" level="h1" typography="display4">
              Standup Picker!
            </Heading>
          </Box>
          <Box flex={1}>
            <SoundEffectOptions
              selectedOption={selectedSound}
              shuffleMode={isShuffleMode}
              setSelection={(sound) => setSelectedSound(sound)}
              setShuffleMode={(shuffleMode) => setIsShuffleMode(shuffleMode)}
            />
          </Box>
        </Grid>
      </StyledHeader>
      {teamState.previouslySelected.length <= activeParticipants.length && (
        <StyledFlex flexDirection="column">
          <Box as="section" textAlign="center">
            <StyledList>
              {teamState.members.map((member: string) => {
                return (
                  <li key={member}>
                    <StyledTextButton
                      hasGivenUpdate={teamState.previouslySelected.includes(
                        member
                      )}
                      isSelected={teamState.selected === member}
                      onClick={() => toggleAbsentParticipant(member)}
                      isAbsent={teamState.absent.includes(member)}
                      allowDisabling={teamState.previouslySelected.length === 0}
                    >
                      {member}
                    </StyledTextButton>
                  </li>
                );
              })}
            </StyledList>
          </Box>
          <Spacer />
          <Box as="section" textAlign="center" minHeight={64}>
            {teamState.selected && !isLooping && (
              <Text typography="display4" fontWeight="normal">
                <Prompt
                  initial={teamState.previouslySelected.length === 1}
                  final={remainingParticipants.length === 0}
                  participant={teamState.selected}
                />
              </Text>
            )}
          </Box>
          <Spacer />
          <Box as="section" textAlign="center" minHeight={64}>
            <PrimaryButton onClick={handleNextPersonClick} disabled={isLooping}>
              {buttonLabel}
            </PrimaryButton>
          </Box>
        </StyledFlex>
      )}
      {appState === "AOBS" && (
        <StyledFlex flexDirection="column">
          <Spacer />
          <Box as="section" textAlign="center" minHeight={64}>
            <Text typography="display4" fontWeight="normal">
              Now that everyone's updated, any AOBs?
            </Text>
          </Box>
          <Box as="section" textAlign="center" minHeight={64}>
            <PrimaryButton
              onClick={() => dispatchAppState({ type: "GO_TO_FINISHED" })}
            >
              We're done!
            </PrimaryButton>
          </Box>
        </StyledFlex>
      )}
      {appState === "FINISHED" && (
        <StyledFlex flexDirection="column">
          <Spacer />
          <Box as="section" textAlign="center" minHeight={64} flex={1}>
            <Text typography="display4" fontWeight="normal">
              {teamEntertainment && entertainmentMap[teamEntertainment] ? (
                entertainmentMap[teamEntertainment]
              ) : (
                <>Have a nice day!</>
              )}
            </Text>
          </Box>
          <Box as="section" textAlign="center" flex={6}></Box>
        </StyledFlex>
      )}
      <SoundPlayer ref={soundPlayerRef} src="" />
      {isRickRoll && (
        <StyledSurpriseVideo
          src="./rickRoll.mp4"
          loop={true}
          autoPlay={true}
          muted={true}
        />
      )}
    </StyledAppContainer>
  );
};

export default App;

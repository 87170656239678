type SoundConfig = {
  [key: string]: {
    name: string;
    src: string;
    ticks: number;
  };
};

export const SOUND_CONFIG: SoundConfig = {
  anotherOneBitesTheDust: {
    name: "Another One Bites the Dust",
    src: "anotherOneBitesTheDust.mp3",
    ticks: 80,
  },
  billyJean: {
    name: "Billie Jean",
    src: "billieJean.mp3",
    ticks: 82,
  },
  bomBom: {
    name: "Bom Bom",
    src: "bomBom.mp3",
    ticks: 83,
  },
  chickenBanana: {
    name: "Chicken Banana",
    src: "chickenBanana.mp3",
    ticks: 75,
  },
  countdown: {
    name: "Countdown",
    src: "countdown.mp3",
    ticks: 75,
  },
  crazyInLove: {
    name: "Crazy in Love",
    src: "crazy-in-love.mp3",
    ticks: 49,
  },
  drumroll: {
    name: "Drumroll",
    src: "drumroll.mp3",
    ticks: 40,
  },
  finalCountdown: {
    name: "Final Countdown",
    src: "finalCountdown.mp3",
    ticks: 85,
  },
  gottaGetThruThis: {
    name: "Gotta Get Thru This",
    src: "gottaGetThruThis.mp3",
    ticks: 75,
  },
  inTooDeep: {
    name: "In Too Deep",
    src: "inTooDeep.mp3",
    ticks: 80,
  },
  jeopardy: {
    name: "Jeopardy",
    src: "jeopardy.mp3",
    ticks: 80,
  },
  johnCena: {
    name: "John Cena",
    src: "johnCena.mp3",
    ticks: 80,
  },
  jumpAround: {
    name: "Jump Around",
    src: "jumpAround.mp3",
    ticks: 90,
  },
  letsGetItOn: {
    name: "Let's Get It On",
    src: "letsGetItOn.mp3",
    ticks: 90,
  },
  littleGreenBag: {
    name: "Little Green Bag",
    src: "littleGreenBag.mp3",
    ticks: 99,
  },
  marioKart: {
    name: "Mario Kart",
    src: "marioKart.mp3",
    ticks: 32,
  },
  ohYeah: {
    name: "Oh Yeah",
    src: "ohYeahIntro.mp3",
    ticks: 115,
  },
  placeYourHands: {
    name: "Place Your Hands",
    src: "placeYourHands.mp3",
    ticks: 80,
  },
  rocky: {
    name: "Rocky",
    src: "rocky.mp3",
    ticks: 105,
  },
  spongebob: {
    name: "Spongebob",
    src: "spongebob-short.mp3",
    ticks: 95,
  },
  salsa: {
    name: "Salsa",
    src: "salsa.mp3",
    ticks: 67,
  },
  superman: {
    name: "Superman",
    src: "superman.mp3",
    ticks: 110,
  },
  underPressure: {
    name: "Under Pressure",
    src: "underPressure.mp3",
    ticks: 85,
  },
  xylophone: {
    name: "Xylophone",
    src: "xylophone.mp3",
    ticks: 85,
  },
};

export const CHEER = {
  src: "crowdCheering.mp3",
};

export const RICK_ROLL_SRC = "neverGonnaGiveYouUp.mp3";
export const RICK_ROLL_MIN = 980;
export const RICK_ROLL_MAX = 1000;
